<template>
  <section class="container">
    <article class="notfind">
      <div class="text">很抱歉，您访问的页面去月球了~~</div>
      <div class="btn"><router-link to="./">返回首页</router-link></div>
    </article>
  </section>
</template>

<script>
export default {
  name: 'App',
  mounted(){
    this.$emit('getBgClass', "404");
  }
}
</script>

<style scoped>
.notfind {
  width: 100%;
  height: 560px;
  margin: 0 auto;
  background: url('../assets/images/404.png') no-repeat center 0;
  text-align: center;
}
.text {
  font-size: 20px;
  padding: 175px 0 12px;
  color: #999;
}
.btn a {
  display: inline-block;
  font-size: 16px;
  line-height: 1.65;
  padding: 0 24px;
  border: 2px solid #94BFF3;
  border-radius: 30px;
  color: #94BFF3;
}
@media screen and (max-width:1023px){
  .notfind{ height: 13.4rem; background-size: auto 11rem; background-position: center 2rem;}
  .text {
    font-size: .48rem;
    padding: 5.48rem 0 0.2rem;
  }
  .btn a {
    font-size: 0.4rem;
    padding: 0 0.6rem;
    border-radius: 0.6rem;
  }
}
</style>
